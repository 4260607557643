<template>
  <div>
    <grid
      v-model:columns="columns"
      v-model:selectedRows="selectedRows"
      v-model="rows"
      :options="options"
      :page="page"
      :per_page="per_page"
      :newRowComponent="newRowComponent"
      :isHideNewRowComponent="isHideNewRowComponent"
      :countRows="countRows"
      :search_text="search_textToGrid"
      @deleteRow="deleteRow"
      @nextPage="nextPage"
      @prevPage="prevPage"
      @saveNewRow="saveNewPartnerSites"
      @saveRow="savePartnerSites"
      @setPerPage="setPerPage"
      @sortRows="fetchData"
      @setTab="setTab"
      @deleteSelected="deleteSelected"
      @exportSelected="exportSelected"
      :listTabs="listTabsProp"
    />
  </div>
  <alert-yes-no
    v-if="isVisibleAfterSaveAlert"
    title="Error"
    :text="textAfterSaveAlert"
    :alertResult="closeAfterSaveAlert"
    :hideYes="false"
    :hideNo="true"
  />
</template>

<script>
import { shallowRef } from 'vue'
import NewRowPartnerSites from '@/components/NewRows/partner/NewRowPartnerSites'
import { fetchData, fetchCSV, urls } from '@/utils/urls.js'
import { hasAccess, permissions } from '@/utils/permissions'
import { listMixin } from '@/utils/list-mixin'
import { GrigOptions } from '@/models/grid-options'
import { GridColumn } from '@/models/grid-column'

export default {
  name: 'PartnerSites',
  mixins: [listMixin],

  emits: ['needToUpdateData', 'setTab'],
  props: {
    listTabsProp: { default: [] },
    partner_id: { default: -1 },
    isCanEditGlobal: { default: false },
    isActivatedToEdit: { default: false },
  },

  data() {
    return {
      options: new GrigOptions({
        addButtonText: 'Add site',
        isEditable: this.isEditable,
        isDelete: this.isDelete,
        isAdd: this.isAdd,
        isExportToCSV: hasAccess(permissions.export_partners_to_csv),
        getDeleteContent: async (rows) => {
          if (!rows) return ``
          return `<div class="mb-10">
              Are you sure you want to delete site${rows.length > 1 ? 's' : ''}?
              ${rows.map((item) => `<div>• ${item.url}</div>`).join('')}
            </div>`
        },
      }),

      columns: [
        new GridColumn({
          key: 'url',
          text: 'Domain',
          formatedValue: ({ value }) => {
            if (value)
              return `<a href="${
                value.includes('http') ? value : '//' + value
              }" title="${value}" target="_blank">${value}</a>`
            return ''
          },
          width: '300px',
          cssClass: 'bold-underline hand',
        }),

        new GridColumn({
          key: 'site_status',
          text: 'Site status',
          width: '100px',
        }),

        new GridColumn({
          key: 'niche',
          text: 'Niche',
          width: '150px',
        }),

        new GridColumn({
          key: 'sub_niche',
          text: 'Sub-niche',
          formatedValue: ({ value }) => {
            if (!value) return ''

            if (value?.length)
              return value.map((item) => item?.sub_niche || item).join(', ')

            return value
          },
        }),

        new GridColumn({
          key: 'commercial_pages',
          text: 'Commercial pages',
          width: '100px',
        }),

        new GridColumn({
          key: 'domain_rating',
          text: 'Domain Rating',
          width: '100px',
        }),

        new GridColumn({
          key: 'organic_traffic',
          text: 'Organic traffic',
          width: '100px',
        }),

        new GridColumn({
          key: 'main_traffic_source',
          text: 'Main traffic source',
          width: '75px',
        }),

        new GridColumn({
          key: 'organic_keywords',
          text: 'Organic keywords',
          width: '100px',
        }),

        new GridColumn({
          key: 'p2p',
          text: 'P2P',
          width: '75px',
        }),

        new GridColumn({
          key: 'created_by',
          text: 'Created by',
          width: '150px',
        }),

        new GridColumn({
          key: 'modified_by',
          text: 'Last edited by',
          width: '150px',
        }),
      ],

      newPartnerSites: {
        url: null,
        niche: null,
        sub_niche: null,
        commercial_pages: null,
        domain_rating: null,
        organic_traffic: null,
        organic_keywords: null,
        site_status: null,
        p2p: null,
      },

      newRowComponent: shallowRef(NewRowPartnerSites),
      initToEdit: -1,
      isVisibleAfterSaveAlert: false,
      textAfterSaveAlert: '',
    }
  },

  watch: {
    isActivatedToEdit() {
      this.options.isEditable = this.isEditable
      this.initToEdit = this.isActivatedToEdit
    },

    isAdd() {
      this.options.isAdd = this.isAdd
    },

    isEditable() {
      this.options.isEditable = this.isEditable
    },

    isDelete() {
      this.options.isDelete = this.isDelete
    },

    rows: {
      handler: function () {
        this.$emit('needToUpdateData', 'statistic')
      },
      deep: true,
    },
  },

  computed: {
    isAdd() {
      return (
        hasAccess(permissions.create_partner_site) &&
        this.isActivatedToEdit &&
        this.initToEdit
      )
    },

    isEditable() {
      return this.isCanEditGlobal && this.isActivatedToEdit && this.initToEdit
      //return hasAccess(permissions.update_partner_site) && this.isActivatedToEdit && this.initToEdit
    },

    isDelete() {
      return (
        hasAccess(permissions.delete_partner_site) &&
        this.isActivatedToEdit &&
        this.initToEdit
      )
    },

    isPartnerContact() {
      return this.$store.state.currentUser?.partner ? true : false
    },
  },

  created() {
    this.initToEdit = this.isActivatedToEdit
    this.hideColumns(['created_by', 'modified_by', 'cost', 'comments'])
    this.fetchData()
  },

  methods: {
    closeAfterSaveAlert() {
      this.isVisibleAfterSaveAlert = false
      this.textAfterSaveAlert = ''
    },

    hideColumns(columnKeys = []) {
      for (const key of columnKeys) {
        let col = this.columns.find((item) => item.key === key)
        if (!col) continue

        col.isHide = this.isPartnerContact
      }
    },

    setTab(id) {
      this.$emit('setTab', id)
    },

    saveNewPartnerSites(partnerSites) {
      this.setNewPartnerSites(partnerSites)
      this.addRow()
    },

    savePartnerSites(partnerSites) {
      this.editRow(partnerSites)
    },

    newPartnerSitesToDefault() {
      for (let key in this.newPartnerSites) {
        this.newPartnerSites[key] = null
      }
    },

    setNewPartnerSites(partnerSites) {
      for (let key in partnerSites) {
        this.newPartnerSites[key] = partnerSites[key]
      }
    },

    async editRow(partnerSites) {
      this.$store.commit('setIsLoading', true)
      const id = partnerSites.id
      delete partnerSites.id

      let body = this.bodyToSave(partnerSites)
      body = { ...body, ...{ partner_id: this.partner_id } }

      let result = await fetchData(urls.partnerSites + `/${id}`, 'POST', body)
      if (result?.need_to_popup) {
        this.textAfterSaveAlert = `
          ${result?.message} ${result?.name || ''}
          `

        this.isVisibleAfterSaveAlert = true
      } else if (!result?.detail) {
        let partnerSitesRow = this.rows.find((item) => item.id === id)
        for (const key in partnerSites) {
          if (key in partnerSitesRow) partnerSitesRow[key] = partnerSites[key]
        }

        this.isHideNewRowComponent = true
        this.$nextTick(() => {
          this.isHideNewRowComponent = false
        })
      } else console.error(result.detail)

      this.$store.commit('setIsLoading', false)
    },

    async addRow() {
      this.$store.commit('setIsLoading', true)

      let body = this.bodyToSave(this.newPartnerSites)
      body = { ...body, ...{ partner_id: this.partner_id } }

      let result = await fetchData(urls.partnerSites, 'POST', body)

      if (result?.need_to_popup) {
        this.textAfterSaveAlert = `
          ${result?.message} ${result?.name || ''}
          `

        this.isVisibleAfterSaveAlert = true
      } else if (!result?.detail) {
        const tmpPartnerSites = this.newPartnerSites
        this.rows.unshift({ ...{ id: result.id }, ...tmpPartnerSites })
        this.isHideNewRowComponent = true
        this.$nextTick(() => {
          this.isHideNewRowComponent = false
        })

        this.newPartnerSitesToDefault()
      } else {
        this.isHideNewRowComponent = false
        console.error(result.detail)
      }

      this.$store.commit('setIsLoading', false)
    },

    async deleteRow(ids) {
      let idsLocal = typeof ids === 'object' ? ids : [ids]
      if (!idsLocal || !idsLocal.length) return

      this.$store.commit('setIsLoading', true)

      const deleteEntityQuery = (ids) => {
        let query = ``
        for (const id of ids) {
          query += `sites_ids=${id}&`
        }

        return query
      }

      let query = `partner_id=${this.partner_id}&`

      if (idsLocal?.length && idsLocal[0] === 'all') {
        query += query = `${
          this.queryToFetch(1, 'all') & deleteEntityQuery(idsLocal)
        }`
      } else {
        query += deleteEntityQuery(idsLocal)
      }

      let result = await fetchData(urls.partnerSites, 'DELETE', null, query)
      if (!result?.detail) {
        for (const id of idsLocal) {
          const idx = this.rows.findIndex((item) => item.id === id)
          this.rows.splice(idx, 1)
        }
        this.selectedRows = []
      } else console.error(result.detail)

      this.$store.commit('setIsLoading', false)
    },

    async exportToCSVRows(ids) {
      this.$store.commit('setIsLoading', true)

      const exportQuery = (ids) => {
        let query = `partner_id=${this.partner_id}&`
        for (const id of ids) {
          query += `sites_ids=${id}&`
        }

        if (query === `partner_id=${this.partner_id}&`) {
          for (const row of this.rows) {
            query += `sites_ids=${row.id}&`
          }
        }

        return query
      }

      let query = ''

      if (ids?.length && ids[0] === 'all') {
        query += query = `${this.queryToFetch(1, 'all')}&${this.queryToSort()}`
      }

      query += exportQuery(ids)

      await fetchCSV(
        urls.partnerSitesToCsv,
        'GET',
        'partner-sites',
        null,
        query
      )

      this.$store.commit('setIsLoading', false)
    },

    async fetchData() {
      if (this.isLoading && !this.listTabs.length) return

      this.$store.commit('setIsLoading', true)
      let query = `page=${this.page}&per_page=${this.per_page}&partner_id=${this.partner_id}&`
      let prevSearch_text = this.search_text.value
      if (this.search_text.value?.length > 2) {
        query += `&search_text=${this.search_text.value}`
      }

      query += this.queryToSort()

      let result = await fetchData(urls.partnerSites, 'GET', null, query)

      this.rows = result?.rows || []
      this.countRows = result?.count || 0
      this.search_textToGrid = this.search_text.value
      this.$store.commit('setIsLoading', false)

      if (
        this.search_text.value?.length > 2 &&
        prevSearch_text !== this.search_text.value
      )
        this.fetchData()
    },
  },
}
</script>
