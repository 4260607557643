<template>
  <div>
    <grid
      v-model:columns="columns"
      v-model:selectedRows="selectedRows"
      v-model="rows"
      :options="options"
      :page="page"
      :per_page="per_page"
      :newRowComponent="newRowComponent"
      :isHideNewRowComponent="isHideNewRowComponent"
      :countRows="countRows"
      :search_text="search_textToGrid"
      @deleteRow="deleteRow"
      @nextPage="nextPage"
      @prevPage="prevPage"
      @saveNewRow="saveNewPartnerLinksFromPartner"
      @saveRow="savePartnerLinksFromPartner"
      @setPerPage="setPerPage"
      @sortRows="fetchData"
      @setTab="setTab"
      @deleteSelected="deleteSelected"
      @exportSelected="exportSelected"
      @showFilters="showFilters"
      :listTabs="listTabsProp"
    />

    <set-filters
      v-if="isVisibleFilters"
      v-model="isVisibleFilters"
      :setFilterComponent="setFilterComponent"
      :filters="filters"
      @setFilters="setFilters"
    />
  </div>
</template>

<script>
import { shallowRef, computed, provide } from 'vue'
import NewRowPartnerLinksFromPartner from '@/components/NewRows/partner/NewRowPartnerLinksFromPartner'
import { fetchData, fetchCSV, urls } from '@/utils/urls.js'
import { hasAccess, permissions } from '@/utils/permissions'
import { listMixin } from '@/utils/list-mixin'
import SetFilterComponent from '@/components/partner/SetFilters/SetFiltersPartnerLinksFromPartner'
import { GrigOptions } from '@/models/grid-options'
import { GridColumn } from '@/models/grid-column'
import CustomFooterComponent from '@/components/partner/CustomFooterPartnerLinks'
import numberToCommasString from '@/utils/text-formatters.js'

export default {
  name: 'PartnerLinksFromPartner',
  mixins: [listMixin],

  emits: ['needToUpdateData', 'setTab'],
  props: {
    listTabsProp: { default: [] },
    partner_id: { default: -1 },
    isCanEditGlobal: { default: false },
    isActivatedToEdit: { default: false },
  },

  data() {
    let filterKeyUrlIndex = 0

    provide(
      'partner_id',
      computed(() => this.partner_id)
    )

    return {
      options: new GrigOptions({
        isFilters: true,
        isEditable: this.isEditable,
        isDelete: this.isDelete,
        isAdd: this.isAdd,
        isExportToCSV: hasAccess(permissions.export_links_from_partner_to_csv),
        addButtonText: 'Add link from partner',
        isShowAllColumns: false,
        getDeleteContent: async (rows) => {
          if (!rows) return ``
          return `<div class="mb-10">
            Are you sure you want to delete link${rows.length > 1 ? 's' : ''}?
              ${rows
                .map(
                  (item) =>
                    `<div>• ${item.url}, ${item.anchor}, ${item.target_page}, ${item.status}, ${item.date_published}</div>`
                )
                .join('')}
            </div>`
        },
        customFooterComponent: {
          data: null,
          component: shallowRef(CustomFooterComponent),
        },
      }),

      columns: [
        new GridColumn({
          key: 'id',
          text: 'Link ID',
          width: '80px',
        }),

        new GridColumn({
          key: 'url',
          text: 'Url',
          formatedValue: ({ value }) => {
            if (value)
              return `<a href="${value}" title="${value}" target="_blank">${value}</a>`
            return ''
          },
          width: '300px',
          cssClass: 'bold-underline hand',
        }),

        new GridColumn({
          key: 'anchor',
          text: 'Anchor',
        }),

        new GridColumn({
          key: 'target_page',
          text: 'Target pages',
          width: '295px',
        }),

        new GridColumn({
          key: 'status',
          text: 'Status',
          width: '120px',
        }),

        // new GridColumn({
        //   key: 'slack_community_status',
        //   text: 'Slack Community Status',
        //   width: '120px',
        // }),

        new GridColumn({
          key: 'slack_community',
          text: 'Slack community',
          formatedValue: ({ row }) => {
            if (row?.slack_community)
              return `
             <div class="checkbox-row checked">
              <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.5" y="0.5" width="13" height="13" rx="2.5"></rect>
              <path d="M4 7L6 9L10 5" stroke="white" stroke-linecap="round" stroke-linejoin="round"></path>
              </svg>
            </div>
             `
            else
              return `
              <div class="checkbox-row">
                <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                 <rect x="0.5" y="0.5" width="13" height="13" rx="2.5"></rect>
                </svg>
              </div>
              `
          },
          width: '100px',
        }),

        new GridColumn({
          key: 'loss_reason',
          text: 'Loss Reason',
          width: '150px',
        }),

        new GridColumn({
          key: 'date_requested',
          text: 'Date requested',
          width: '150px',
        }),

        new GridColumn({
          key: 'date_published',
          text: 'Date published',
          width: '150px',
        }),

        new GridColumn({
          key: 'p2p',
          text: 'P2P',
          width: '75px',
        }),

        new GridColumn({
          key: 'commercial_pages_link',
          text: 'Content page',
          width: '100px',
        }),

        new GridColumn({
          key: 'comments',
          text: 'Comments',
          width: '250px',
        }),
        new GridColumn({
          key: 'cost',
          text: '$ Cost',
          formatedValue: ({ value }) => {
            let result = numberToCommasString(value)
            if (result !== 0 && !result) return value

            return `$${result}`
          },
          width: '100px',
        }),

        new GridColumn({
          key: 'dr',
          text: 'DR',
          width: '75px',
        }),

        new GridColumn({
          key: 'date_status_changed',
          text: 'Date Status changed',
          width: '150px',
        }),

        new GridColumn({
          key: 'rescued',
          text: 'Rescued',
          formatedValue: ({ row }) => {
            if (row?.rescued)
              return `
           <div class="checkbox-row checked">
            <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="0.5" y="0.5" width="13" height="13" rx="2.5"></rect>
            <path d="M4 7L6 9L10 5" stroke="white" stroke-linecap="round" stroke-linejoin="round"></path>
            </svg>
          </div>
           `
            else
              return `
            <div class="checkbox-row">
              <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
               <rect x="0.5" y="0.5" width="13" height="13" rx="2.5"></rect>
              </svg>
            </div>
            `
          },
          width: '100px',
        }),

        new GridColumn({
          key: 'date_created',
          text: 'Date Created',
          width: '150px',
        }),

        new GridColumn({
          key: 'client_name',
          text: 'Client',
        }),

        new GridColumn({
          key: 'parser_status',
          text: `Parser's Status`,
          width: '150px',
        }),

        new GridColumn({
          key: 'created_by',
          text: 'Created by',
          width: '150px',
        }),

        new GridColumn({
          key: 'modified_by',
          text: 'Last edited by',
          width: '150px',
        }),
      ],

      setFilterComponent: shallowRef(SetFilterComponent),
      filters: {
        link_status_f: [
          // 'Published',
          // 'Failed',
          // 'Declined',
          // 'Removed',
          // 'Pitched',
          // 'No-follow',
          // 'Undefined',
          // '404 error',
          // 'No longer a client',
          // 'UGC tag',
          // 'Sponsored',
          // 'No-index',
          // 'Canonical',
          // 'Approved',
          // 'Negotiation',
          // 'Scheduled',
          // 'Recalled',
        ],
      },

      groupToFilter: {
        link_status_f: {
          keyUrl: 'f' + filterKeyUrlIndex++,
          key: 'link_status_f',
          name: 'Link status',
        },
      },

      newPartnerLinksFromPartner: {
        url: null,
        anchor: null,
        comments: null,
        status: null,
        target_page: null,
        date_published: null,
        cost: null,
        dr: null,
        client_id: null,
      },

      newRowComponent: shallowRef(NewRowPartnerLinksFromPartner),
      initToEdit: -1,
    }
  },

  watch: {
    isActivatedToEdit() {
      this.options.isEditable = this.isEditable
      this.initToEdit = this.isActivatedToEdit
    },

    isAdd() {
      this.options.isAdd = this.isAdd
    },

    isEditable() {
      this.options.isEditable = this.isEditable
    },

    isDelete() {
      this.options.isDelete = this.isDelete
    },

    rows: {
      handler: function () {
        this.$emit('needToUpdateData', 'statistic')
      },
      deep: true,
    },
  },

  computed: {
    isAdd() {
      return (
        hasAccess(permissions.create_link_from_partner) &&
        this.isActivatedToEdit &&
        this.initToEdit
      )
    },

    isEditable() {
      return this.isCanEditGlobal && this.isActivatedToEdit && this.initToEdit
      //return hasAccess(permissions.update_link_from_partner) && this.isActivatedToEdit && this.initToEdit
    },

    isDelete() {
      return (
        hasAccess(permissions.delete_partner_links_from_do_partner) &&
        this.isActivatedToEdit &&
        this.initToEdit
      )
    },

    isPartnerContact() {
      return this.$store.state.currentUser?.partner ? true : false
    },
  },

  created() {
    this.initToEdit = this.isActivatedToEdit
    this.hideColumns(['created_by', 'modified_by', 'cost', 'comments'])
    this.fetchData()
  },

  methods: {
    showFilters() {
      this.isVisibleFilters = true
    },

    hideColumns(columnKeys = []) {
      for (const key of columnKeys) {
        let col = this.columns.find((item) => item.key === key)
        if (!col) continue

        col.isHide = this.isPartnerContact
      }
    },

    setTab(id) {
      this.$emit('setTab', id)
    },

    saveNewPartnerLinksFromPartner(partnerLinksFromPartner) {
      this.setNewPartnerLinksFromPartner(partnerLinksFromPartner)
      this.addRow()
    },

    savePartnerLinksFromPartner(partnerLinksFromPartner) {
      this.editRow(partnerLinksFromPartner)
    },

    newPartnerLinksFromPartnerToDefault() {
      for (let key in this.newPartnerLinksFromPartner) {
        this.newPartnerLinksFromPartner[key] = null
      }
    },

    setNewPartnerLinksFromPartner(partnerLinksFromPartner) {
      for (let key in partnerLinksFromPartner) {
        this.newPartnerLinksFromPartner[key] = partnerLinksFromPartner[key]
      }
    },

    async editRow(partnerLinksFromPartner) {
      this.$store.commit('setIsLoading', true)
      const id = partnerLinksFromPartner.id
      delete partnerLinksFromPartner.id

      let body = this.bodyToSave(partnerLinksFromPartner)
      body = { ...body, ...{ partner_id: this.partner_id } }

      let result = await fetchData(
        urls.partnerLinksFromPartnerCreate + `/${id}`,
        'POST',
        body
      )
      if (!result?.detail) {
        let partnerLinksFromPartnerRow = this.rows.find(
          (item) => item.id === id
        )

        for (const key in result) {
          if (key in partnerLinksFromPartnerRow)
            partnerLinksFromPartnerRow[key] = result[key]
        }

        this.isHideNewRowComponent = true
        this.$nextTick(() => {
          this.isHideNewRowComponent = false
        })
      } else console.error(result.detail)

      this.$store.commit('setIsLoading', false)
    },

    async addRow() {
      this.$store.commit('setIsLoading', true)

      let body = this.bodyToSave(this.newPartnerLinksFromPartner)
      body = { ...body, ...{ partner_id: this.partner_id } }

      let result = await fetchData(
        urls.partnerLinksFromPartnerCreate,
        'POST',
        body
      )
      if (!result?.detail) {
        const tmpPartnerLinksFromPartner = this.newPartnerLinksFromPartner
        this.rows.unshift({
          ...{ id: result.id },
          ...tmpPartnerLinksFromPartner,
        })
        this.isHideNewRowComponent = true
        this.$nextTick(() => {
          this.isHideNewRowComponent = false
        })

        this.newPartnerLinksFromPartnerToDefault()
      } else {
        this.isHideNewRowComponent = false
        console.error(result.detail)
      }

      this.$store.commit('setIsLoading', false)
    },

    async deleteRow(ids) {
      let idsLocal = typeof ids === 'object' ? ids : [ids]
      if (!idsLocal || !idsLocal.length) return

      this.$store.commit('setIsLoading', true)

      const deleteEntityQuery = (ids) => {
        let query = ``
        for (const id of ids) {
          query += `links_ids=${id}&`
        }

        return query
      }

      let query = `partner_id=${this.partner_id}&`

      if (idsLocal?.length && idsLocal[0] === 'all') {
        query += query = `${
          this.queryToFetch(1, 'all') & deleteEntityQuery(idsLocal)
        }`
      } else {
        query += deleteEntityQuery(idsLocal)
      }

      let result = await fetchData(
        urls.partnerLinksFromDoPartnerDelete,
        'DELETE',
        null,
        query
      )
      if (!result?.detail) {
        for (const id of idsLocal) {
          const idx = this.rows.findIndex((item) => item.id === id)
          this.rows.splice(idx, 1)
        }
        this.selectedRows = []
      } else console.error(result.detail)

      this.$store.commit('setIsLoading', false)
    },

    async exportToCSVRows(ids) {
      this.$store.commit('setIsLoading', true)

      const exportQuery = (ids) => {
        let query = `partner_id=${this.partner_id}&`
        for (const id of ids) {
          query += `links_ids=${id}&`
        }

        if (query === `partner_id=${this.partner_id}&`) {
          for (const row of this.rows) {
            query += `links_ids=${row.id}&`
          }
        }

        return query
      }

      let query = ''

      if (ids?.length && ids[0] === 'all') {
        query += query = `${this.queryToFetch(
          1,
          'all'
        )}&${this.queryToFilters()}&${this.queryToSort()}`
      }

      query += exportQuery(ids)

      await fetchCSV(
        urls.partnerLinksFromPartnerToCsv,
        'GET',
        'links-from-partner',
        null,
        query
      )

      this.$store.commit('setIsLoading', false)
    },

    async fetchData() {
      if (this.isLoading && !this.listTabs.length) return

      this.$store.commit('setIsLoading', true)
      let query = `partner_id=${this.partner_id}&`
      let prevSearch_text = this.search_text.value

      query += `${this.queryToFetch()}&${this.queryToFilters()}&${this.queryToSort()}`

      let result = await fetchData(
        urls.partnerLinksFromPartner,
        'GET',
        null,
        query
      )

      this.rows = result?.rows || []
      this.countRows = result?.count || 0
      this.search_textToGrid = this.search_text.value
      this.$store.commit('setIsLoading', false)
      this.options.customFooterComponent.data = result?.totals || null

      if (
        this.search_text.value?.length > 2 &&
        prevSearch_text !== this.search_text.value
      )
        this.fetchData()
    },
  },
}
</script>
