<template>
    <tr class="add-row">
        <td></td>
        <td>
            <div class="add-row-buttons">
                <div class="main-button orange no-background sm" @click="save">Save</div>
                <div class="main-button no-background sm" @click="cancel">Cancel</div>
            </div>
        </td>
        <td>
            <div class="table-cell" :style="setWidthColumn(getColumn('url'))">
                <input-text v-model="url"/>
            </div>
        </td>
        <td>
            <div class="table-cell" :style="setWidthColumn(getColumn('anchor'))">
                <input-text v-model="anchor"/>
            </div>
        </td>
        <td></td>
        <td></td>
    </tr>
    <alert-yes-no v-if="isVisibleConfirmAlert" title="Error" :text="textConfirmAlert" :alertResult="alertConfirmResult" :hideYes="false" :hideNo="true"/>
</template>

<script>
import { newRowMixin } from '@/utils/new-row-mixin'

export default {
    name: 'NewRowPartnerTargetPages',
    mixins: [newRowMixin],

    data() {
        let regExpUrl = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,10}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/

        return {
            url: {
                value: null,
                isValid: false,
                clearable: true,
                required: true,
                readonly: false,
                errorKey: '',
                class: 'sm',
                regexp: regExpUrl,
            },   
            anchor: {
                value: null,
                isValid: false,
                clearable: true,
                required: true,
                readonly: false,
                errorKey: '',
                class: 'sm'
            },         
        }
    },

      created() {
        for (let key in this.rowData) {
            const element = this.rowData[key]
            if(key in this.$data) {
                this.$data[key].value = element
                if(element || element === 0 || element === false) this.$data[key].isValid = true  
            }
        }
    },

    computed: {
        isValid() {
            if(!this.url.isValid || !this.anchor.isValid)
            return false

            return true
        }
    },

    methods: {
        
        cancel() {
            this.$emit('cancel')
        },

        save() {
            if(!this.url.isValid) {
                this.textConfirmAlert = 'Enter a valid URL.'
                this.isVisibleConfirmAlert = true
                return
            }

            if(!this.isValid) return

            this.$emit('save', this.rowForSave())
        },
    }
}
</script>
