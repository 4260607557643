<template>
  <div class="filters-form">
    <div class="filters-row">
      <div class="form-column" style="margin-right: 0">
        <div class="form-label">{{ labels.link_status_f }}</div>
        <div class="form-inline">
          <div>
            <input-select
              v-model="link_status_f"
              @changeValue="changeValueLink_status_f"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputSelect from '@/components/inputs/InputSelect'
import { fetchData, urls } from '@/utils/urls.js'
import { filtersMixin } from '@/utils/filters-mixin'

export default {
  name: 'SetFiltersLinks',
  mixins: [filtersMixin],
  components: { InputSelect },
  props: {
    groupToFilter: { default: () => ({}), type: Object },
  },

  data() {
    return {
      link_status_f: {
        value: [], // Выбранные значения. Если ничего не выбрано передавать пустой массив
        isValid: true, // Валидно ли поле
        clearable: true, // Показывает значок очистки
        required: false, // Обязательность
        readonly: false, // Редактируемость
        isMultiselect: true, // Возможность множественного выбора
        isTags: false, // Показывать в виде тегов
        isLiveSearch: true, // Показывать возможность фильтрации по строке
        dataList: [
          // Данные для выпадающего списка
        ],
      },

      labels: {
        link_status_f: 'Link status',
      },
    }
  },

  created() {
    this.fetchDataLink_status_f()

    for (let key in this.filters) {
      const element = this.filters[key]
      if (key in this.$data) {
        this.$data[key].value = element
      }
    }
  },

  methods: {
    changeValueLink_status_f(value) {
      if (value?.length)
        this.$emit('setFilterValue', { key: 'link_status_f', value: value })
      else this.$emit('setFilterValue', { key: 'link_status_f', value: null })
    },

    async fetchDataLink_status_f() {
      let query = `page=1&per_page=all&link_status=asc`
      let result = await fetchData(urls.linkStatuses, 'GET', null, query)

      this.link_status_f.dataList = (result?.rows || []).map((item) => {
        return {
          id: item.link_status,
          name: item.link_status,
        }
      })
    },
  },
}
</script>
