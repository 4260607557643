<template>
  <div>
    <grid
      v-model:columns="columns"
      v-model:selectedRows="selectedRows"
      v-model="rows"
      :options="options"
      :page="page"
      :per_page="per_page"
      :newRowComponent="newRowComponent"
      :isHideNewRowComponent="isHideNewRowComponent"
      :countRows="countRows"
      :search_text="search_textToGrid"
      @deleteRow="deleteRow"
      @nextPage="nextPage"
      @prevPage="prevPage"
      @saveNewRow="saveNewPartnerTargetPage"
      @saveRow="savePartnerTargetPage"
      @setPerPage="setPerPage"
      @sortRows="fetchData"
      @setTab="setTab"
      @deleteSelected="deleteSelected"
      @exportSelected="exportSelected"
      :listTabs="listTabsProp"
    />
  </div>
</template>

<script>
import { shallowRef } from 'vue'
import NewRowPartnerTargetPage from '@/components/NewRows/partner/NewRowPartnerTargetPage'
import { fetchData, fetchCSV, urls } from '@/utils/urls.js'
import { hasAccess, permissions } from '@/utils/permissions'
import { listMixin } from '@/utils/list-mixin'
import { GrigOptions } from '@/models/grid-options'
import { GridColumn } from '@/models/grid-column'

export default {
  name: 'PartnerTargetPages',
  mixins: [listMixin],

  emits: ['needToUpdateData', 'setTab'],

  props: {
    listTabsProp: { default: [] },
    partner_id: { default: -1 },
    isCanEditGlobal: { default: false },
    isActivatedToEdit: { default: false },
  },

  data() {
    return {
      permissions,

      options: new GrigOptions({
        isEditable: this.isEditable,
        isDelete: this.isDelete,
        isAdd: this.isAdd,
        isExportToCSV: hasAccess(permissions.export_target_pages_to_csv),
        addButtonText: 'Add target page',
        getDeleteContent: async (rows) => {
          if (!rows) return ``
          return `<div class="mb-10">
            Are you sure you want to delete target page${
              rows.length > 1 ? 's' : ''
            }?
              ${rows.map((item) => `<div>• ${item.url}</div>`).join('')}
            </div>`
        },
      }),

      columns: [
        new GridColumn({
          key: 'url',
          text: 'Url',
          formatedValue: ({ value }) => {
            if (value)
              return `<a href="${value}" title="${value}" target="_blank">${value}</a>`
            return ''
          },
          width: '350px',
          cssClass: 'bold-underline hand',
        }),

        new GridColumn({
          key: 'anchor',
          text: 'Anchor',
          width: '250px',
        }),

        new GridColumn({
          key: 'created_by',
          text: 'Created by',
          width: '150px',
        }),

        new GridColumn({
          key: 'modified_by',
          text: 'Last edited by',
          width: '150px',
        }),
      ],
      newPartnerTargetPage: {
        url: null,
        anchor: null,
      },
      newRowComponent: shallowRef(NewRowPartnerTargetPage),
      initToEdit: -1,
    }
  },

  watch: {
    isActivatedToEdit() {
      this.options.isEditable = this.isEditable
      this.initToEdit = this.isActivatedToEdit
    },

    isAdd() {
      this.options.isAdd = this.isAdd
    },

    isEditable() {
      this.options.isEditable = this.isEditable
    },

    isDelete() {
      this.options.isDelete = this.isDelete
    },
  },

  computed: {
    isAdd() {
      return (
        hasAccess(permissions.create_target_page) &&
        this.isActivatedToEdit &&
        this.initToEdit
      )
    },

    isEditable() {
      return this.isCanEditGlobal && this.isActivatedToEdit && this.initToEdit
      //return hasAccess(permissions.update_target_page) && this.isActivatedToEdit && this.initToEdit
    },

    isDelete() {
      return (
        hasAccess(permissions.delete_target_pages) &&
        this.isActivatedToEdit &&
        this.initToEdit
      )
    },

    isPartnerContact() {
      return this.$store.state.currentUser?.partner ? true : false
    },
  },

  created() {
    this.initToEdit = this.isActivatedToEdit
    this.hideColumns(['created_by', 'modified_by', 'cost', 'comments'])
    this.fetchData()
  },

  methods: {
    hideColumns(columnKeys = []) {
      for (const key of columnKeys) {
        let col = this.columns.find((item) => item.key === key)
        if (!col) continue

        col.isHide = this.isPartnerContact
      }
    },

    hasAccess,
    setTab(id) {
      this.$emit('setTab', id)
    },

    saveNewPartnerTargetPage(partnerTargetPage) {
      this.setNewPartnerTargetPage(partnerTargetPage)
      this.addRow()
    },

    savePartnerTargetPage(partnerTargetPage) {
      this.editRow(partnerTargetPage)
    },

    newPartnerTargetPageToDefault() {
      for (let key in this.newPartnerTargetPage) {
        if (key === 'partner_id')
          this.newPartnerTargetPage[key] = this.partner_id
        else this.newPartnerTargetPage[key] = null
      }
    },

    setNewPartnerTargetPage(partnerTargetPage) {
      for (let key in partnerTargetPage) {
        this.newPartnerTargetPage[key] = partnerTargetPage[key]
      }
    },

    async editRow(partnerTargetPage) {
      this.$store.commit('setIsLoading', true)
      const id = partnerTargetPage.id
      delete partnerTargetPage.id

      let body = this.bodyToSave(partnerTargetPage)
      body = { ...body, ...{ partner_id: this.partner_id } }

      let result = await fetchData(
        urls.partnerTargetPages + `/${id}`,
        'POST',
        body
      )
      if (!result?.detail) {
        let partnerTargetPageRow = this.rows.find((item) => item.id === id)
        for (const key in partnerTargetPage) {
          if (key in partnerTargetPageRow)
            partnerTargetPageRow[key] = partnerTargetPage[key]
        }

        this.isHideNewRowComponent = true
        this.$nextTick(() => {
          this.isHideNewRowComponent = false
        })
      } else console.error(result.detail)

      this.$store.commit('setIsLoading', false)
    },

    async addRow() {
      this.$store.commit('setIsLoading', true)

      let body = this.bodyToSave(this.newPartnerTargetPage)
      body = { ...body, ...{ partner_id: this.partner_id } }

      let result = await fetchData(urls.partnerTargetPages, 'POST', body)
      if (!result?.detail) {
        const tmpPartnerTargetPage = this.newPartnerTargetPage
        this.rows.unshift({ ...{ id: result.id }, ...tmpPartnerTargetPage })
        this.isHideNewRowComponent = true
        this.$nextTick(() => {
          this.isHideNewRowComponent = false
        })

        this.newPartnerTargetPageToDefault()
      } else {
        this.isHideNewRowComponent = false
        console.error(result.detail)
      }

      this.$store.commit('setIsLoading', false)
    },

    async deleteRow(ids) {
      let idsLocal = typeof ids === 'object' ? ids : [ids]
      if (!idsLocal || !idsLocal.length) return
      this.$store.commit('setIsLoading', true)

      const deleteEntityQuery = (ids) => {
        let query = ``
        for (const id of ids) {
          query += `target_pages_ids=${id}&`
        }

        return query
      }

      let query = `partner_id=${this.partner_id}&`

      if (idsLocal?.length && idsLocal[0] === 'all') {
        query += query = `${
          this.queryToFetch(1, 'all') & deleteEntityQuery(idsLocal)
        }`
      } else {
        query += deleteEntityQuery(idsLocal)
      }

      let result = await fetchData(
        urls.partnerTargetPages,
        'DELETE',
        null,
        query
      )
      if (!result?.detail) {
        for (const id of idsLocal) {
          const idx = this.rows.findIndex((item) => item.id === id)
          this.rows.splice(idx, 1)
        }
        this.selectedRows = []
      } else console.error(result.detail)

      this.$store.commit('setIsLoading', false)
    },

    /* async deleteRow(id) {
       this.$store.commit('setIsLoading', true)

       let result = await fetchData(urls.partnerTargetPages+`/${id}`, 'DELETE') 
       if(!result?.detail){ 
        const idx = this.rows.findIndex(item => item.id === id)
        this.rows.splice(idx, 1)
       }
       else console.error(result.detail)

       this.$store.commit('setIsLoading', false)
    },*/

    async exportToCSVRows(ids) {
      this.$store.commit('setIsLoading', true)

      const exportQuery = (ids) => {
        let query = `partner_id=${this.partner_id}&`
        for (const id of ids) {
          query += `target_pages_ids=${id}&`
        }

        if (query === `partner_id=${this.partner_id}&`) {
          for (const row of this.rows) {
            query += `target_pages_ids=${row.id}&`
          }
        }

        return query
      }

      let query = ''

      if (ids?.length && ids[0] === 'all') {
        query += query = `${this.queryToFetch(1, 'all')}&${this.queryToSort()}`
      }

      query += exportQuery(ids)

      await fetchCSV(
        urls.partnerTargetPagesToCsv,
        'GET',
        'target-pages',
        null,
        query
      )

      this.$store.commit('setIsLoading', false)
    },

    async fetchData() {
      if (this.isLoading && !this.listTabsProp.length) return

      this.$store.commit('setIsLoading', true)
      let query = `page=${this.page}&per_page=${this.per_page}&partner_id=${this.partner_id}&`
      let prevSearch_text = this.search_text.value
      if (this.search_text.value?.length > 2) {
        query += `&search_text=${this.search_text.value}`
      }

      query += this.queryToSort()

      let result = await fetchData(urls.partnerTargetPages, 'GET', null, query)
      this.rows = result?.rows || []

      this.countRows = result?.count || 0
      this.search_textToGrid = this.search_text.value
      this.$store.commit('setIsLoading', false)

      if (
        this.search_text.value?.length > 2 &&
        prevSearch_text !== this.search_text.value
      )
        this.fetchData()
    },
  },
}
</script>
