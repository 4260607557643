<template>
  <tr class="add-row">
    <td></td>
    <td>
      <div class="add-row-buttons">
        <div class="main-button orange no-background sm" @click="save">
          Save
        </div>
        <div class="main-button no-background sm" @click="cancel">Cancel</div>
      </div>
    </td>
    <td>
      <div class="table-cell" :style="setWidthColumn(getColumn('url'))">
        <input-text v-model="url" />
      </div>
    </td>
    <td>
      <div class="table-cell" :style="setWidthColumn(getColumn('site_status'))">
        <input-select v-model="site_status" />
      </div>
    </td>
    <td>
      <div class="table-cell" :style="setWidthColumn(getColumn('niche'))">
        <input-select v-model="niche" />
      </div>
    </td>
    <td>
      <div class="table-cell" :style="setWidthColumn(getColumn('sub_niche'))">
        <input-select v-model="sub_niche" />
      </div>
    </td>
    <td>
      <div
        class="table-cell"
        :style="setWidthColumn(getColumn('commercial_pages'))"
      >
        <input-select v-model="commercial_pages" />
      </div>
    </td>
    <td>
      <div
        class="table-cell"
        :style="setWidthColumn(getColumn('domain_rating'))"
      >
        <input-number v-model="domain_rating" />
      </div>
    </td>
    <td>
      <div
        class="table-cell"
        :style="setWidthColumn(getColumn('organic_traffic'))"
      >
        <input-number v-model="organic_traffic" />
      </div>
    </td>

    <td>
      <div
        class="table-cell"
        :style="setWidthColumn(getColumn('main_traffic_source'))"
      >
        {{ rowData['main_traffic_source'] }}
      </div>
    </td>

    <td>
      <div
        class="table-cell"
        :style="setWidthColumn(getColumn('organic_keywords'))"
      >
        <input-number v-model="organic_keywords" />
      </div>
    </td>
    <td>
      <div class="table-cell" :style="setWidthColumn(getColumn('p2p'))">
        <input-select v-model="p2p" />
      </div>
    </td>
    <td></td>
    <td></td>
  </tr>
  <alert-yes-no
    v-if="isVisibleConfirmAlert"
    title="Error"
    :text="textConfirmAlert"
    :alertResult="alertConfirmResult"
    :hideYes="false"
    :hideNo="true"
  />
</template>

<script>
import InputSelect from '@/components/inputs/InputSelect'
import InputNumber from '@/components/inputs/InputNumber'
import { fetchData, urls } from '@/utils/urls.js'
import { newRowMixin } from '@/utils/new-row-mixin'

export default {
  name: 'PartnerSites',
  mixins: [newRowMixin],
  components: { InputSelect, InputNumber },

  data() {
    //let regExpUrl = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,10}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/

    return {
      url: {
        value: null,
        isValid: false,
        clearable: true,
        required: true,
        readonly: false,
        errorKey: '',
        class: 'sm',
        //regexp: regExpUrl,
      },

      niche: {
        value: [], // Выбранные значения. Если ничего не выбрано передавать пустой массив
        isValid: false, // Валидно ли поле
        clearable: true, // Показывает значок очистки
        required: false, // Обязательность
        readonly: false, // Редактируемость
        isMultiselect: false, // Возможность множественного выбора
        isTags: false, // Показывать в виде тегов
        isLiveSearch: true, // Показывать возможность фильтрации по строке
        dataList: [
          // Данные для выпадающего списка
        ],
        class: 'sm',
      },
      site_status: {
        value: [], // Выбранные значения. Если ничего не выбрано передавать пустой массив
        isValid: false, // Валидно ли поле
        clearable: true, // Показывает значок очистки
        required: false, // Обязательность
        readonly: false, // Редактируемость
        isMultiselect: false, // Возможность множественного выбора
        isTags: false, // Показывать в виде тегов
        isLiveSearch: true, // Показывать возможность фильтрации по строке
        dataList: [
          // Данные для выпадающего списка
        ],
        class: 'sm',
      },
      sub_niche: {
        value: [], // Выбранные значения. Если ничего не выбрано передавать пустой массив
        isValid: false, // Валидно ли поле
        clearable: true, // Показывает значок очистки
        required: false, // Обязательность
        readonly: false, // Редактируемость
        isMultiselect: true, // Возможность множественного выбора
        isTags: false, // Показывать в виде тегов
        isLiveSearch: true, // Показывать возможность фильтрации по строке
        dataList: [
          // Данные для выпадающего списка
        ],
        class: 'sm',
      },

      commercial_pages: {
        value: [], // Выбранные значения. Если ничего не выбрано передавать пустой массив
        isValid: false, // Валидно ли поле
        clearable: true, // Показывает значок очистки
        required: true, // Обязательность
        readonly: false, // Редактируемость
        isMultiselect: false, // Возможность множественного выбора
        isTags: false, // Показывать в виде тегов
        isLiveSearch: true, // Показывать возможность фильтрации по строке
        dataList: [
          // Данные для выпадающего списка
          {
            id: 'Yes',
            name: 'Yes',
          },
          {
            id: 'No',
            name: 'No',
          },
          {
            id: 'Maybe',
            name: 'Maybe',
          },
        ],
        class: 'sm',
      },

      domain_rating: {
        value: null,
        isValid: false,
        clearable: true,
        required: true,
        readonly: false,
        errorKey: '',
        class: 'sm',
      },

      organic_traffic: {
        value: null,
        isValid: false,
        clearable: true,
        required: true,
        readonly: false,
        errorKey: '',
        class: 'sm',
      },

      organic_keywords: {
        value: null,
        isValid: false,
        clearable: true,
        required: true,
        readonly: false,
        errorKey: '',
        class: 'sm',
      },

      p2p: {
        value: [], // Выбранные значения. Если ничего не выбрано передавать пустой массив
        isValid: false, // Валидно ли поле
        clearable: true, // Показывает значок очистки
        required: true, // Обязательность
        readonly: false, // Редактируемость
        isMultiselect: false, // Возможность множественного выбора
        isTags: false, // Показывать в виде тегов
        isLiveSearch: false, // Показывать возможность фильтрации по строке
        dataList: [
          { id: 'Yes', name: 'Yes' },
          { id: 'No', name: 'No' }, // Данные для выпадающего списка
        ],
        class: 'sm',
      },
    }
  },

  async created() {
    await this.fetchDataNiche()
    this.initSub_NicheDataList()
    await this.fetchDataSite_status()

    for (let key in this.rowData) {
      const element = this.rowData[key]
      if (key in this.$data) {
        if (key === 'sub_niche') {
          if (element && typeof element === 'object' && element?.length) {
            this.$data[key].value = element.map(
              (item) => item?.sub_niche || item
            )
          } else if (
            element &&
            typeof element === 'object' &&
            !element?.length
          ) {
            this.$data[key].value = []
          } else if (!element) {
            this.$data[key].value = []
          } else {
            this.$data[key].value = [element]
          }
        } else if (element && typeof element === 'object' && element.length)
          this.$data[key].value = element
        else if (!element) continue
        else if (key === 'niche') this.$data[key].value = [element]
        else if (key === 'site_status') this.$data[key].value = [element]
        else if (key === 'p2p') this.$data[key].value = [element]
        else if (key === 'commercial_pages') this.$data[key].value = [element]
        else this.$data[key].value = element
        if (element || element === 0 || element === false)
          this.$data[key].isValid = true
      }
    }
  },

  watch: {
    'niche.value': {
      deep: true,
      handler() {
        if (!this.niche.value.length) {
          this.sub_niche.dataList = []
          return
        }

        this.initSub_NicheDataList()
      },
    },
  },

  computed: {
    isValid() {
      for (const key in this.$data) {
        let entityValue = this.$data[key]
        if (
          typeof entityValue === 'object' &&
          'isValid' in entityValue &&
          !entityValue.isValid
        )
          return false
      }

      return true
    },
  },

  methods: {
    initSub_NicheDataList() {
      let niche = this.niche.dataList.find(
        (item) => item.name === this.niche.value[0]
      ) || { sub_niches: [] }
      this.sub_niche.dataList = niche.sub_niches.map((item) => {
        return {
          id: item.sub_niche,
          name: item.sub_niche,
        }
      })
    },

    setBoolValue(key, value) {
      this.$data[key].value = value
    },

    async fetchDataNiche() {
      let query = `page=1&per_page=all&niche=asc`
      let result = await fetchData(urls.niches, 'GET', null, query)

      this.niche.dataList = (result?.rows || []).map((item) => {
        return {
          id: item.niche,
          name: item.niche,
          sub_niches: (item.sub_niches || []).sort(function (a, b) {
            if (a.sub_niche < b.sub_niche) {
              return -1
            }
            if (a.sub_niche > b.sub_niche) {
              return 1
            }
            return 0
          }),
        }
      })
    },

    async fetchDataSite_status() {
      let query = `page=1&per_page=all&site_status=asc`
      let result = await fetchData(urls.siteStatuses, 'GET', null, query)

      this.site_status.dataList = (result?.rows || []).map((item) => {
        return {
          id: item.site_status,
          name: item.site_status,
        }
      })
    },

    cancel() {
      this.$emit('cancel')
    },

    save() {
      if (!this.url.isValid) {
        this.textConfirmAlert = 'Enter a valid URL.'
        this.isVisibleConfirmAlert = true
        return
      }

      if (!this.isValid) return

      this.$emit('save', this.rowForSave())
    },
  },
}
</script>
