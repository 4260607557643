<template>
  <tr class="add-row">
    <td></td>
    <td>
      <div class="add-row-buttons">
        <div class="main-button orange no-background sm" @click="save">
          Save
        </div>
        <div class="main-button no-background sm" @click="cancel">Cancel</div>
      </div>
    </td>
    <td>
      <div class="table-cell" :style="setWidthColumn(getColumn('id'))">
        {{ rowData?.id }}
      </div>
    </td>
    <td>
      <div class="table-cell" :style="setWidthColumn(getColumn('url'))">
        <input-text v-model="url" />
      </div>
    </td>
    <td>
      <div class="table-cell" :style="setWidthColumn(getColumn('anchor'))">
        <input-text v-model="anchor" />
      </div>
    </td>
    <td>
      <div class="table-cell" :style="setWidthColumn(getColumn('target_page'))">
        <input-text v-model="target_page" />
      </div>
    </td>
    <td>
      <div class="table-cell" :style="setWidthColumn(getColumn('status'))">
        <input-select v-model="status" />
      </div>
    </td>
    <!-- <td>
      <div
        class="table-cell"
        :style="setWidthColumn(getColumn('slack_community_status'))"
      >
        <input-select v-model="slack_community_status" />
      </div>
    </td> -->
    <td>
      <div
        class="table-cell"
        :style="setWidthColumn(getColumn('date_published'))"
      >
        <input-date-picker v-model="date_published" />
      </div>
    </td>
    <td>
      <div
        class="table-cell"
        :style="setWidthColumn(getColumn('commercial_pages_link'))"
      >
        <input-select v-model="commercial_pages_link" />
      </div>
    </td>
    <td>
      <div class="table-cell" :style="setWidthColumn(getColumn('comments'))">
        <input-text v-model="comments" />
      </div>
    </td>
    <td>
      <div class="table-cell" :style="setWidthColumn(getColumn('cost'))">
        <input-number v-model="cost" />
      </div>
    </td>
    <td>
      <div class="table-cell" :style="setWidthColumn(getColumn('dr'))">
        {{ rowData?.dr }}
      </div>
    </td>
    <td>
      <div
        class="table-cell"
        :style="setWidthColumn(getColumn('date_status_changed'))"
      >
        {{ rowData?.date_status_changed }}
      </div>
    </td>
    <td>
      <div class="table-cell" :style="setWidthColumn(getColumn('rescued'))">
        <div class="checkbox-row checked" v-if="rowData?.rescued">
          <svg
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect x="0.5" y="0.5" width="13" height="13" rx="2.5"></rect>
            <path
              d="M4 7L6 9L10 5"
              stroke="white"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
          </svg>
        </div>
        <div class="checkbox-row" v-else>
          <svg
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect x="0.5" y="0.5" width="13" height="13" rx="2.5"></rect>
          </svg>
        </div>
      </div>
    </td>
    <td>
      <div
        class="table-cell"
        :style="setWidthColumn(getColumn('date_created'))"
      >
        {{ rowData?.date_created }}
      </div>
    </td>
    <td></td>
    <td></td>
  </tr>
  <alert-yes-no
    v-if="isVisibleConfirmAlert"
    title="Error"
    :text="textConfirmAlert"
    :alertResult="alertConfirmResult"
    :hideYes="false"
    :hideNo="true"
  />
</template>

<script>
import { inject, shallowRef } from 'vue'
import InputDatePicker from '@/components/inputs/InputDatePicker'
import InputSelect from '@/components/inputs/InputSelect'
import InputNumber from '@/components/inputs/InputNumber'
import { fetchData, urls } from '@/utils/urls.js'
import { newRowMixin } from '@/utils/new-row-mixin'

export default {
  name: 'NewRowPartnerLinksFromDo',
  mixins: [newRowMixin],
  components: { InputDatePicker, InputSelect, InputNumber },

  data() {
    let regExpUrl =
      /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,10}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/

    return {
      partner_id: shallowRef(inject('partner_id')).value,
      url: {
        value: null,
        isValid: false,
        clearable: true,
        required: true,
        readonly: false,
        errorKey: '',
        class: 'sm',
        regexp: regExpUrl,
      },
      anchor: {
        value: null,
        isValid: false,
        clearable: true,
        required: true,
        readonly: false,
        errorKey: '',
        class: 'sm',
      },

      commercial_pages_link: {
        value: [], // Выбранные значения. Если ничего не выбрано передавать пустой массив
        isValid: false, // Валидно ли поле
        clearable: true, // Показывает значок очистки
        required: true, // Обязательность
        readonly: false, // Редактируемость
        isMultiselect: false, // Возможность множественного выбора
        isTags: false, // Показывать в виде тегов
        isLiveSearch: true, // Показывать возможность фильтрации по строке
        dataList: [
          // Данные для выпадающего списка
          {
            id: 'Yes',
            name: 'Yes',
          },
          {
            id: 'No',
            name: 'No',
          },
          // {
          //   id: 'Maybe',
          //   name: 'Maybe',
          // },
        ],
        class: 'sm',
      },

      comments: {
        value: null,
        isValid: true,
        clearable: true,
        required: false,
        readonly: false,
        errorKey: '',
        class: 'sm',
      },

      /* target_page: {
                    value: [], // Выбранные значения. Если ничего не выбрано передавать пустой массив
                    isValid: false, // Валидно ли поле
                    clearable: true, // Показывает значок очистки
                    required: false, // Обязательность
                    readonly: false, // Редактируемость
                    isMultiselect: false, // Возможность множественного выбора 
                    isTags: false, // Показывать в виде тегов
                    isLiveSearch: true, // Показывать возможность фильтрации по строке
                    dataList: [ // Данные для выпадающего списка
                    ],
                    class: 'sm'
                },*/

      target_page: {
        value: null,
        isValid: false,
        clearable: true,
        required: true,
        readonly: false,
        errorKey: '',
        class: 'sm',
        regexp: regExpUrl,
      },

      date_published: {
        value: null,
        isValid: false,
        clearable: true,
        required: true,
        readonly: false,
        errorKey: '',
        class: 'sm',
      },

      cost: {
        value: null,
        isValid: true,
        clearable: true,
        required: false,
        readonly: false,
        errorKey: '',
        class: 'sm',
      },

      status: {
        value: [], // Выбранные значения. Если ничего не выбрано передавать пустой массив
        isValid: false, // Валидно ли поле
        clearable: true, // Показывает значок очистки
        required: false, // Обязательность
        readonly: false, // Редактируемость
        isMultiselect: false, // Возможность множественного выбора
        isTags: false, // Показывать в виде тегов
        isLiveSearch: true, // Показывать возможность фильтрации по строке
        dataList: [
          // Данные для выпадающего списка
        ],
        class: 'sm',
      },

      // slack_community_status: {
      //   value: [], // Выбранные значения. Если ничего не выбрано передавать пустой массив
      //   isValid: true, // Валидно ли поле
      //   clearable: true, // Показывает значок очистки
      //   required: false, // Обязательность
      //   readonly: false, // Редактируемость
      //   isMultiselect: false, // Возможность множественного выбора
      //   isTags: false, // Показывать в виде тегов
      //   isLiveSearch: true, // Показывать возможность фильтрации по строке
      //   dataList: [
      //     // Данные для выпадающего списка
      //   ],
      //   class: 'sm',
      // },
    }
  },

  created() {
    this.fetchDataLinkStatus()
    //this.fetchDataSlackCommunityStatus()
    //this.fetchDataTarget_page()
    for (let key in this.rowData) {
      const element = this.rowData[key]
      if (key in this.$data) {
        if (element && typeof element === 'object' && element.length)
          this.$data[key].value = element
        else if (!element) continue
        else if (key === 'status') this.$data[key].value = [element]
        else if (key === 'slack_community_status')
          this.$data[key].value = [element]
        else if (key === 'commercial_pages_link')
          this.$data[key].value = [element]
        else this.$data[key].value = element

        if (element || element === 0 || element === false)
          this.$data[key].isValid = true
      }
    }

    if (
      this.status.value &&
      (this.status.value.includes('Declined') ||
        this.status.value.includes('Recalled') ||
        this.status.value.includes('Failed'))
    ) {
      this.comments.required = true
      if (!this.comments.value) this.comments.isValid = false
    } else {
      this.comments.required = false
      this.comments.isValid = true
    }

    if (this.status.value && this.status.value.includes('Undefined')) {
      this.commercial_pages_link.isValid = true
      this.commercial_pages_link.required = false
    } else {
      this.commercial_pages_link.isValid =
        this.commercial_pages_link.value &&
        this.commercial_pages_link.value.length
          ? true
          : false
      this.commercial_pages_link.required = true
    }
  },

  computed: {
    isValid() {
      for (const key in this.$data) {
        let entityValue = this.$data[key]
        if (
          typeof entityValue === 'object' &&
          'isValid' in entityValue &&
          !entityValue.isValid
        )
          return false
      }

      return true
    },
  },

  watch: {
    'status.value': {
      deep: true,
      handler() {
        //Монипуляции с урлом нужны чтобы прошла перевалидация урла после смены статуса
        const tmp = this.url.value
        this.url.value = tmp + ' '
        this.$nextTick(() => {
          this.url.value = tmp
        })

        if (
          this.status.value &&
          (this.status.value.includes('Declined') ||
            this.status.value.includes('Recalled') ||
            this.status.value.includes('Failed'))
        ) {
          this.comments.required = true
          if (!this.comments.value) this.comments.isValid = false
        } else {
          this.comments.required = false
          this.comments.isValid = true
        }

        if (this.status.value && this.status.value.includes('Undefined')) {
          this.commercial_pages_link.isValid = true
          this.commercial_pages_link.required = false
        } else {
          this.commercial_pages_link.isValid =
            this.commercial_pages_link.value &&
            this.commercial_pages_link.value.length
              ? true
              : false
          this.commercial_pages_link.required = true
        }
      },
    },
  },

  methods: {
    setBoolValue(key, value) {
      this.$data[key].value = value
    },

    /* async fetchDataTarget_page() {
             let query = `page=1&per_page=all&partner_id=${this.partner_id}`
 
             let result = await fetchData(urls.partnerTargetPages, 'GET', null,query) 
             this.target_page.dataList = (result?.rows || []).map(item => {
                 return {
                     id: item.url,    
                     name: item.url
                 }
             })
         },*/

    async fetchDataLinkStatus() {
      let query = `page=1&per_page=all&link_status=asc`
      let result = await fetchData(urls.linkStatuses, 'GET', null, query)

      this.status.dataList = (result?.rows || []).map((item) => {
        return {
          id: item.link_status,
          name: item.link_status,
        }
      })
    },

    // async fetchDataSlackCommunityStatus() {
    //   let query = `page=1&per_page=all&link_status=asc`
    //   let result = await fetchData(
    //     urls.slackCommunityStatus,
    //     'GET',
    //     null,
    //     query
    //   )

    //   this.slack_community_status.dataList = (result?.rows || []).map((item) => {
    //     return {
    //       id: item.slack_community_status,
    //       name: item.slack_community_status,
    //     }
    //   })
    // },

    cancel() {
      this.$emit('cancel')
    },

    isValidUrl(keyStatus, keyUrl) {
      if (
        this[keyStatus].value &&
        this[keyStatus].value.includes('Published')
      ) {
        let tmpReplasedUrl = (this[keyUrl].value || '')
          .replace('https://', '')
          .replace('http://', '')
        let firstIdx = tmpReplasedUrl.indexOf('/')
        if (firstIdx < 0) return false

        let tmpSlicedUrl = tmpReplasedUrl.slice(firstIdx + 1)

        if (tmpSlicedUrl.length < 7) return false
      }
      return true
    },

    save() {
      if (!this.url.isValid || !this.target_page.isValid) {
        this.textConfirmAlert = 'Enter a valid URL.'
        this.isVisibleConfirmAlert = true
        return
      }

      if (!this.isValidUrl('status', 'url')) {
        this.textConfirmAlert =
          'The link with status Published should have at least 7 symbols after basic URL.'
        this.url.isValid = false
        this.isVisibleConfirmAlert = true
        return
      }

      if (!this.isValid) return

      this.$emit('save', this.rowForSave())
    },
  },
}
</script>
