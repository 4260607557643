<template>
  <header-page :title="titlePage" :hasBackLink="false" />
  <div class="profile-form">
    <div class="form-column w-900px">
      <div>
        <div class="title title1">
          Profile info
          <template v-if="can_edit">
            <template v-if="isPartnerContact === false">
              <div
                v-if="!isActivatedToEdit"
                class="icon edit"
                v-html="Edit"
                @click="setIsActivatedToEditInputs(false)"
              ></div>
              <div
                v-if="isActivatedToEdit"
                class="main-button sm center orange w-75px ml-25 mb-5 mt-5"
                :class="{ disabled: !isValid || !smthChanged() }"
                @click="saveProfile"
              >
                Save
              </div>
              <div
                v-if="isActivatedToEdit && entityId > -1"
                class="main-button sm center w-75px ml-25 mb-5 mt-5"
                @click="initDefaultFormValues"
              >
                Cancel
              </div>
            </template>
          </template>
        </div>
        <div class="form-row">
          <div class="form-cell w-450" data-key="company_name">
            <div class="label">Company name *</div>
            <input-text v-model="company_name" />
          </div>

          <div class="form-cell w-450" data-key="pitchbox">
            <div class="label">Link to Pitchbox profile *</div>
            <input-link v-model="pitchbox" />
          </div>
        </div>

        <div class="form-row">
          <div class="form-cell w-450" data-key="status">
            <div class="label">Company Status *</div>
            <input-select v-model="status" />
          </div>
          <div class="form-cell w-450" data-key="link_builder">
            <div class="label">Link builder *</div>
            <input-select v-model="link_builder" />
          </div>
        </div>

        <div class="form-row">
          <div class="form-cell w-450" data-key="slack_community_status">
            <div class="label">Slack Community Status</div>
            <input-select v-model="slack_community_status" />
          </div>
          <div class="form-cell w-450" data-key="guest_blogging_opp">
            <div class="label">Guest blogging opp</div>
            <input-select v-model="guest_blogging_opp" />
          </div>
        </div>

        <div class="form-row">
          <div class="form-cell w-450" data-key="additional_requirements">
            <div class="label">Additional Requirements</div>
            <input-select v-model="additional_requirements" />
          </div>

          <div class="form-cell w-450" data-key=""></div>
        </div>

        <div
          class="form-row"
          v-if="entityId > 0 && (date_status_changed || date_owner_changed)"
        >
          <div class="form-cell w-450" data-key="date_status_changed">
            <template v-if="date_status_changed">
              <div class="label">Company Status Changed date</div>
              <div class="mb-5">{{ date_status_changed }}</div>
            </template>
          </div>
          <div class="form-cell w-450" data-key="date_owner_changed">
            <template v-if="date_owner_changed">
              <div class="label">Owner Changed date</div>
              <div class="mb-5">{{ date_owner_changed }}</div>
            </template>
          </div>
        </div>

        <div class="form-row" v-if="!isPartnerContact">
          <div class="form-cell" data-key="status_details">
            <div class="label">Status details</div>
            <input-text v-model="status_details" />
          </div>
        </div>

        <div class="form-row" v-if="!isPartnerContact">
          <div class="form-cell" data-key="notes">
            <div class="label">Comments</div>
            <input-text-multilines v-model="notes" />
          </div>
        </div>

        <div class="form-row" v-if="entityId > 0 && !isPartnerContact">
          <div class="form-cell w-450" data-key="created_by">
            <div class="label">Created by</div>
            <div class="mb-5">{{ created_by }}</div>
          </div>
          <div class="form-cell w-450" data-key="modified_by">
            <div class="label">Last edited by</div>
            <div class="mb-5">{{ modified_by }}</div>
          </div>
        </div>

        <div class="form-row" v-if="entityId > 0">
          <div class="form-cell w-450" data-key="date_created">
            <div class="label">Created Date</div>
            <div class="mb-5">{{ date_created }}</div>
          </div>
          <div class="form-cell w-450" data-key="date_changed">
            <div class="label">Last edited date</div>
            <div class="mb-5">{{ date_changed }}</div>
          </div>
        </div>
      </div>

      <template v-if="contacts.length">
        <div
          class="form-dynamic"
          v-for="(item, index) in isShowOther ? contacts : [contacts[0]]"
          :key="item.id"
        >
          <profile-contact
            :contact="item"
            :isPartnerContact="isPartnerContact"
            :isCanToDelete="contacts.length > 1"
            :isActivatedToEdit="hasPermissionToEdit"
            @contactValueChanged="contactValueChanged"
            @saveForm="saveProfile"
          />

          <div
            v-if="!index && contacts.length > 1"
            class="show-other-button"
            @click="isShowOther = !isShowOther"
          >
            {{ isShowOther ? 'Hide other contacts' : 'Show other contacts' }}
          </div>
        </div>
      </template>

      <div v-if="hasPermissionToEdit" class="add-button" @click="onAddContact">
        <div class="icon" v-html="Plus"></div>
        <div class="text">Add contact</div>
      </div>
    </div>
    <div class="form-column w-50">
      <div class="statistic" v-if="entityId > 0">
        <div class="title1">Statistic</div>
        <div class="statistic-row" v-for="(item, key) in statistic" :key="key">
          {{ item }} {{ getStatisticName(key) }}
        </div>
        <div
          v-if="entityId > 0 && hasAccess(permissions.get_partner_history)"
          class="text-left"
        >
          <router-link
            class="main-button orange"
            :to="`/partners-history/${entityId}`"
            >History</router-link
          >
        </div>
      </div>
    </div>
  </div>

  <div class="profile-grid" v-if="entityId > 0">
    <partner-target-pages
      v-if="activeTab === 'partner-target-pages'"
      :listTabsProp="listTabs"
      @setTab="setTab"
      :partner_id="this.entityId"
      :isCanEditGlobal="can_edit"
      :isActivatedToEdit="isActivatedToEdit"
    />

    <partner-links-from-do
      v-else-if="activeTab === 'partner-links-from-do'"
      :listTabsProp="listTabs"
      @setTab="setTab"
      :partner_id="this.entityId"
      :isCanEditGlobal="can_edit"
      :isActivatedToEdit="isActivatedToEdit"
      @needToUpdateData="needToUpdateData"
    />

    <partner-links-from-partner
      v-else-if="activeTab === 'partner-links-from-partner'"
      :listTabsProp="listTabs"
      @setTab="setTab"
      :partner_id="this.entityId"
      :isCanEditGlobal="can_edit"
      :isActivatedToEdit="isActivatedToEdit"
      @needToUpdateData="needToUpdateData"
    />

    <partner-sites
      v-else-if="activeTab === 'partner-sites'"
      :listTabsProp="listTabs"
      @setTab="setTab"
      :partner_id="this.entityId"
      :isCanEditGlobal="can_edit"
      :isActivatedToEdit="isActivatedToEdit"
      @needToUpdateData="needToUpdateData"
    />
  </div>

  <alert-yes-no
    v-if="isVisibleChangeContactEmailConfirmAlert"
    title="Confirm"
    :text="changeContactEmailConfirmAlertText"
    :alertResult="alertChangeContactEmail"
  />

  <alert-yes-no
    v-if="isVisibleConfirmSiteAlert"
    title="Attention"
    text="The field is missing: Sites. Please, add at least one site that your partner owns."
    :alertResult="closeAddSiteModal"
    :hideYes="false"
    :hideNo="true"
  />

  <alert-yes-no
    v-if="isVisibleAfterSaveAlert"
    title="Attention"
    :text="messageAfterSave"
    :alertResult="closeAfterSaveModal"
    :hideYes="false"
    :hideNo="true"
  />

  <alert-yes-no
    v-if="isVisibleSaveContactAlert"
    title="Attention"
    :text="messageSaveContact"
    :alertResult="closeSaveContactModal"
    :hideYes="false"
    :hideNo="true"
  />

  <alert-yes-no
    v-if="isVisibleCreatePartnerAlert"
    title="Attention"
    :text="messageCreatePertner"
    :alertResult="closeCreatePartnerModal"
    :hideYes="false"
    :hideNo="true"
  />
</template>

<script>
import HeaderPage from '@/components/HeaderPage.vue'
import { Edit, Plus } from '@/utils/icons'
import InputText from '@/components/inputs/InputText'
import InputTextMultilines from '@/components/inputs/InputTextMultilines'
import InputLink from '@/components/inputs/InputLink'
import InputSelect from '@/components/inputs/InputSelect'
import ProfileContact from '@/components/partner/ProfileContact'
import PartnerTargetPages from '@/views/partner/PartnerTargetPages'
import PartnerLinksFromDo from '@/views/partner/PartnerLinksFromDo'
import PartnerLinksFromPartner from '@/views/partner/PartnerLinksFromPartner'
import PartnerSites from '@/views/partner/PartnerSites'
import { fetchData, urls } from '@/utils/urls.js'
import { hasAccess, permissions } from '@/utils/permissions'
import AlertYesNo from '@/components/AlertYesNo.vue'

export default {
  name: 'LinkBuilderPartner',
  components: {
    HeaderPage,
    InputText,
    InputLink,
    InputSelect,
    ProfileContact,
    PartnerTargetPages,
    PartnerLinksFromDo,
    PartnerLinksFromPartner,
    PartnerSites,
    AlertYesNo,
    InputTextMultilines,
  },

  data() {
    return {
      isVisibleSaveContactAlert: false,
      isVisibleAfterSaveAlert: false,
      isVisibleCreatePartnerAlert: false,
      messageCreatePertner: '',
      messageAfterSave: '',
      messageSaveContact: '',
      permissions,
      isVisibleConfirmSiteAlert: false,
      isVisibleChangeContactEmailConfirmAlert: false,
      changeContactEmailConfirmAlertText: '',
      isMounted: false,
      Edit,
      Plus,
      isShowOther: false,
      isActivatedToEdit: false,

      company_name: {
        valueOriginal: null,
        value: null,
        isValid: false,
        clearable: false,
        required: true,
        readonly: !this.hasPermissionToEdit,
        maxlength: 45,
      },

      status: {
        valueOriginal: [],
        value: [], // Выбранные значения. Если ничего не выбрано передавать пустой массив
        isValid: false, // Валидно ли поле
        clearable: false, // Показывает значок очистки
        required: true, // Обязательность
        readonly: !this.hasPermissionToEdit, // Редактируемость
        isMultiselect: false, // Возможность множественного выбора
        isTags: false, // Показывать в виде тегов
        isLiveSearch: true, // Показывать возможность фильтрации по строке
        dataList: [
          // Данные для выпадающего списка
        ],
        type: 'select',
      },

      link_builder: {
        valueOriginal: [],
        value: [], // Выбранные значения. Если ничего не выбрано передавать пустой массив
        isValid: false, // Валидно ли поле
        clearable: false, // Показывает значок очистки
        required: true, // Обязательность
        readonly: !this.hasPermissionToEdit, // Редактируемость
        isMultiselect: false, // Возможность множественного выбора
        isTags: false, // Показывать в виде тегов
        isLiveSearch: true, // Показывать возможность фильтрации по строке
        dataList: [
          // Данные для выпадающего списка
        ],
        type: 'select',
      },

      slack_community_status: {
        valueOriginal: [],
        value: [], // Выбранные значения. Если ничего не выбрано передавать пустой массив
        isValid: true, // Валидно ли поле
        clearable: false, // Показывает значок очистки
        required: false, // Обязательность
        readonly: !this.hasPermissionToEdit, // Редактируемость
        isMultiselect: false, // Возможность множественного выбора
        isTags: false, // Показывать в виде тегов
        isLiveSearch: true, // Показывать возможность фильтрации по строке
        dataList: [
          // Данные для выпадающего списка
        ],
        type: 'select',
      },

      pitchbox: {
        valueOriginal: null,
        value: null,
        isValid: false,
        clearable: false,
        required: true,
        readonly: !this.hasPermissionToEdit,
      },

      status_details: {
        valueOriginal: null,
        value: null,
        isValid: false,
        clearable: false,
        required: false,
        readonly: !this.hasPermissionToEdit,
      },

      notes: {
        valueOriginal: null,
        value: null,
        isValid: true,
        clearable: false,
        required: false,
        readonly: !this.hasPermissionToEdit,
      },

      guest_blogging_opp: {
        valueOriginal: [],
        value: [], // Выбранные значения. Если ничего не выбрано передавать пустой массив
        isValid: false, // Валидно ли поле
        clearable: false, // Показывает значок очистки
        required: false, // Обязательность
        readonly: !this.hasPermissionToEdit, // Редактируемость
        isMultiselect: false, // Возможность множественного выбора
        isTags: false, // Показывать в виде тегов
        isLiveSearch: true, // Показывать возможность фильтрации по строке
        dataList: [
          { id: 'Yes', name: 'Yes' },
          { id: 'No', name: 'No' },
        ],
        type: 'select',
      },

      additional_requirements: {
        valueOriginal: [],
        value: [], // Выбранные значения. Если ничего не выбрано передавать пустой массив
        isValid: false, // Валидно ли поле
        clearable: false, // Показывает значок очистки
        required: false, // Обязательность
        readonly: !this.hasPermissionToEdit, // Редактируемость
        isMultiselect: false, // Возможность множественного выбора
        isTags: false, // Показывать в виде тегов
        isLiveSearch: true, // Показывать возможность фильтрации по строке
        dataList: [],
        type: 'select',
      },

      contacts: [],
      entityId: this.$route.params.id,
      statistic: {
        link_gap: 0,
        published_links_from_do: 0,
        published_links_partner: 0,
        pending_links_from_do: 0,
        pending_links_partner: 0,
        undefined_links_partner: 0,
        declined_links_partner: 0,
        published_slack_community_links_partner: 0,
        slack_community: 0,
      },

      listTabs: [],
      activeTab: '',
      created_by: '',
      modified_by: '',
      date_created: '',
      date_changed: '',
      date_status_changed: '',
      date_owner_changed: '',
      can_edit: this.$route?.params?.id == -1 ? true : false,
      isInitedValues: false,
    }
  },

  computed: {
    isValid() {
      for (const key in this.$data) {
        let dataItem = this.$data[key]
        if (
          dataItem &&
          typeof dataItem === 'object' &&
          'valueOriginal' in dataItem &&
          !dataItem.isValid
        )
          return false
      }

      for (const contact of this.contacts) {
        for (const contactKey in contact) {
          if (contact[contactKey]?.isValid === false) return false
        }
      }
      return true
    },

    hasPermissionToEdit() {
      if (this.can_edit && this.entityId > 0 && this.isActivatedToEdit)
        return true
      if (hasAccess(permissions.update_partner) && this.isActivatedToEdit)
        return true

      return false
    },

    isPartnerContact() {
      if (this.entityId == -1) return false
      return this.$store.state.currentUser?.partner ? true : false
    },

    titlePage() {
      if (this.entityId > 0 && this.company_name.value)
        return this.company_name.value
      return 'New Link Building Partner'
    },

    currentUser() {
      return this.$store.state.currentUser
    },
  },

  watch: {
    'status.value': {
      handler() {
        if (this.status.value && this.status.value[0] !== 'Active') {
          this.status_details.required = true
          if (!this.status_details.value) this.status_details.isValid = false

          // this.notes.required = true
          // if(!this.notes.value) this.notes.isValid = false
        } else {
          this.status_details.required = false
          this.status_details.isValid = true

          // this.notes.required = false
          // this.notes.isValid = true
        }
      },
      deep: true,
    },
  },

  async created() {
    this.fetchDataStatus()
    this.fetchDataLink_builder()
    this.fetchDataSlack_community_status()
    this.fetchDataAdditional_requirements()
    this.isActivatedToEdit = this.entityId == -1 ? true : false
    this.setIsActivatedToEditInputs(!this.isActivatedToEdit)

    if (this.entityId != -1 && this.entityId) {
      let values = await this.loadData(this.entityId)
      this.can_edit = values?.can_edit || false
      this.initFormValues(values)

      if (this.currentUser?.user_data?.role === 'manager') {
        this.link_builder.readonly = true
        this.link_builder.isValid = true
      }
    } else {
      if (this.currentUser?.user_data?.role === 'manager') {
        this.link_builder.value = [this.currentUser?.user_data?.email]
        this.link_builder.readonly = true
        this.link_builder.isValid = true
      }

      this.onAddContact()
    }

    this.initListTabs()
    await this.loadCountTabs()
    ;(this.activeTab = this.$route?.query?.tab
      ? this.$route.query.tab
      : 'partner-sites'),
      this.setTab(this.activeTab)
  },

  methods: {
    closeCreatePartnerModal() {
      this.isVisibleCreatePartnerAlert = false
    },

    closeSaveContactModal() {
      this.isVisibleSaveContactAlert = false
    },

    closeAfterSaveModal() {
      this.isVisibleAfterSaveAlert = false
    },

    closeAddSiteModal() {
      this.isVisibleConfirmSiteAlert = false
    },

    async needToUpdateData(updateType) {
      if (updateType === 'statistic') {
        let values = await this.loadData(this.entityId)

        if (values?.statistic) {
          for (const key in this.statistic) {
            if (Object.hasOwnProperty.call(values?.statistic, key)) {
              this.statistic[key] = values?.statistic[key]
            } else {
              this.statistic[key] = 0
            }
          }
        }
      }

      this.loadCountTabs()
    },

    hasAccess,

    async alertChangeContactEmail(result) {
      if (result) await this.saveProfileData()
      this.isVisibleChangeContactEmailConfirmAlert = false
    },

    async showAlert() {
      let contacts = this.contacts.filter(
        (item) =>
          (item.id.value > 0) & (item.email.value !== item.email.valueOriginal)
      )
      if (!contacts.length) await this.saveProfileData()
      else {
        this.changeContactEmailConfirmAlertText = contacts
          .map((item) => {
            return `
          <div class="mb-10">• Once you send new invite to <b>${item.email.value}</b> an access with current email <b>${item.email.valueOriginal}</b> will be denied.</div>
          `
          })
          .join('')
        this.isVisibleChangeContactEmailConfirmAlert = true
      }
    },

    initListTabs() {
      let listTabs = []
      if (hasAccess(permissions.get_sites))
        listTabs.push({
          id: 'partner-sites',
          name: 'Sites',
          count: 0,
          isActive: true,
          isVisible: true,
        })

      if (hasAccess(permissions.get_partner_links_from_do))
        listTabs.push({
          id: 'partner-links-from-do',
          name: 'Links from DO',
          count: 0,
          isActive: false,
          isVisible: true,
        })

      if (hasAccess(permissions.get_partner_links_from_partner))
        listTabs.push({
          id: 'partner-links-from-partner',
          name: 'Links From Partner',
          count: 0,
          isActive: false,
          isVisible: true,
        })

      if (hasAccess(permissions.get_target_pages))
        listTabs.push({
          id: 'partner-target-pages',
          name: 'Target pages',
          count: 0,
          isActive: false,
          isVisible: true,
        })

      this.listTabs = listTabs
    },

    async loadCountTabs() {
      let query = `page=${1}&per_page=${1}&partner_id=${this.entityId}`

      let targetPages = this.listTabs.find(
        (item) => item.id === 'partner-target-pages'
      )
      if (targetPages) {
        let partnerTargetPagesResult = await fetchData(
          urls.partnerTargetPages,
          'GET',
          null,
          query
        )
        targetPages.count = partnerTargetPagesResult?.count || 0
      }

      let linksFromDo = this.listTabs.find(
        (item) => item.id === 'partner-links-from-do'
      )
      if (linksFromDo) {
        let partnerLinksFromDoResult = await fetchData(
          urls.partnerLinksFromDo,
          'GET',
          null,
          query
        )
        linksFromDo.count = partnerLinksFromDoResult?.count || 0
      }

      let partnerLinksFromPartner = this.listTabs.find(
        (item) => item.id === 'partner-links-from-partner'
      )
      if (partnerLinksFromPartner) {
        let partnerLinksFromPartnerResult = await fetchData(
          urls.partnerLinksFromPartner,
          'GET',
          null,
          query
        )
        partnerLinksFromPartner.count =
          partnerLinksFromPartnerResult?.count || 0
      }

      let partnerSites = this.listTabs.find(
        (item) => item.id === 'partner-sites'
      )
      if (partnerSites) {
        let partnerSitesResult = await fetchData(
          urls.partnerSites,
          'GET',
          null,
          query
        )
        partnerSites.count = partnerSitesResult?.count || 0
      }
    },

    setTab(id) {
      if (!id) return

      this.activeTab = this.listTabs.find((item) => item.id === id)
        ? id
        : this.listTabs[0].id

      this.listTabs.forEach((item) => {
        if (item.id === this.activeTab) item.isActive = true
        else item.isActive = false
      })

      this.$router.push({ query: { tab: this.activeTab } })
    },

    getStatisticName(key) {
      switch (key) {
        case 'pending_links_from_do':
          return 'Pending links from DO'

        case 'pending_links_partner':
          return 'Pending links from Partner'

        case 'published_links_partner':
          return 'Published links from Partner'

        case 'published_links_from_do':
          return 'Published links from DO'

        case 'link_gap':
          return 'Link gap'

        case 'undefined_links_partner':
          return 'Undefined links from Partner'

        case 'declined_links_partner':
          return 'Declined links from Partner'

        case 'published_slack_community_links_partner':
          return 'Published Slack Community links from Partner'

        case 'slack_community':
          return 'Slack Community'

        default:
          return key
      }
    },

    contactValueChanged({ id, key, value, isValid }) {
      let contact = this.contacts.find((item) => item.id.value === id)
      if (!contact) return

      if (key === 'is_primary') {
        this.contacts.forEach((item) => {
          item.is_primary.value = false
          item.is_primary.valueOriginal = null
        })
      }

      if (key === 'isMarkedToDelete') {
        if (id <= 0) {
          let index = this.contacts.findIndex((item) => item.id.value === id)
          if (index > -1) this.contacts.splice(index, 1)
        }

        contact.isMarkedToDelete = value
        return
      }

      contact[key].value = value
      contact[key].isValid = isValid
    },

    setIsActivatedToEditInputs(value) {
      this.isActivatedToEdit = !value
      for (const key in this.$data) {
        let dataItem = this.$data[key]
        if (
          dataItem &&
          typeof dataItem === 'object' &&
          'valueOriginal' in dataItem
        ) {
          dataItem.readonly = !this.hasPermissionToEdit
        }
      }
    },

    smthChanged() {
      for (const key in this.$data) {
        let dataItem = this.$data[key]
        if (
          dataItem &&
          typeof dataItem === 'object' &&
          'valueOriginal' in dataItem
        ) {
          if (dataItem.value && typeof dataItem.value === 'object') {
            if (
              JSON.stringify(dataItem.value) !==
              JSON.stringify(dataItem.valueOriginal)
            )
              return true
          } else {
            if (dataItem.value !== dataItem.valueOriginal) {
              return true
            }
          }
        }
      }

      for (const contact of this.contacts) {
        if (this.smthContactChanged(contact)) return true
      }

      return false
    },

    smthContactChanged(contact) {
      for (const contactKey in contact) {
        if (contact[contactKey].value !== contact[contactKey].valueOriginal) {
          return true
        }
        if (contactKey === 'isMarkedToDelete' && contact[contactKey])
          return true
      }

      return false
    },

    initDefaultFormValues() {
      this.setIsActivatedToEditInputs(true)
      for (const key in this.$data) {
        let dataItem = this.$data[key]
        if (typeof dataItem === 'object' && 'valueOriginal' in dataItem) {
          if (dataItem?.type === 'select') {
            dataItem.value = [...dataItem.valueOriginal]
          } else {
            dataItem.value = dataItem.valueOriginal
          }
        }
      }
    },

    initFormValues(values) {
      this.isInitedValues = false

      for (const key in values) {
        if (key in this.$data) {
          let dataItem = this.$data[key]

          if (
            dataItem &&
            typeof dataItem === 'object' &&
            'valueOriginal' in dataItem
          ) {
            if (dataItem.type === 'select') {
              if (values[key]) {
                dataItem.value =
                  typeof values[key] === 'object' ? values[key] : [values[key]]
                dataItem.valueOriginal =
                  typeof values[key] === 'object' ? values[key] : [values[key]]
              } else {
                dataItem.value = []
                dataItem.valueOriginal = []
              }
            } else {
              dataItem.value = values[key]
              dataItem.valueOriginal = values[key]
            }
          } else if ('contacts' === key) {
            this.$data[key] = values[key]
              .sort((a, b) => b.is_primary - a.is_primary)
              .map((item) => {
                let result = {}
                for (const key in item) {
                  result = {
                    ...result,
                    ...{
                      [key]: {
                        value: item[key],
                        valueOriginal: item[key],
                        isValid: true,
                      },
                    },
                  }
                }

                result = { ...result, ...{ isMarkedToDelete: false } }
                return result
              })
          } else if ('statistic' === key) {
            for (const key in this.statistic) {
              if (Object.hasOwnProperty.call(values?.statistic, key)) {
                this.statistic[key] = values?.statistic[key]
              } else {
                this.statistic[key] = 0
              }
            }
          }
        }
      }

      if (values) {
        this.created_by = values.created_by
        this.modified_by = values.modified_by
        this.date_status_changed = values.date_status_changed
        this.date_owner_changed = values.date_owner_changed
        this.date_created = values.date_created
        this.date_changed = values.date_changed
      }

      this.$nextTick(() => {
        this.isInitedValues = true
      })
    },

    initContactFormValues(values, oldId = null) {
      let contact = null
      if (oldId) {
        contact = this.contacts.find((item) => item.id.value === oldId)
      } else contact = this.contacts.find((item) => item.id.value === values.id)

      if (!contact) return

      for (const key in values) {
        if (key in contact) {
          contact[key].value = values[key]
          contact[key].valueOriginal = values[key]
          contact[key].isUpdate = true
          this.$nextTick(() => {
            delete contact[key].isUpdate
          })
        }
      }
    },

    async loadData(id) {
      this.$store.commit('setIsLoading', true)

      let result = await fetchData(urls.partner + '/' + id, 'GET')
      this.$store.commit('setIsLoading', false)
      if (!result?.detail) {
        return result
      } else console.error(result.detail)
      return null
    },

    async saveProfile() {
      if (!this.isValid) {
        console.error('isValid', false)
        return
      }

      if (!this.smthChanged()) {
        console.warn('Nothing to save')
        return
      }

      let partnerSites = this.listTabs.find(
        (item) => item.id === 'partner-sites'
      )
      if (!partnerSites?.count && this.entityId > 0) {
        this.isVisibleConfirmSiteAlert = true
        return
      }

      this.showAlert()
    },

    onAddContact() {
      this.isShowOther = true
      let id = Number('-' + Math.random())
      this.contacts.push({
        isMarkedToDelete: false,

        is_primary: {
          value: this.contacts.length ? false : true,
          valueOriginal: null,
          isValid: true,
        },

        id: {
          value: id,
          valueOriginal: id,
          isValid: true,
        },

        first_name: {
          valueOriginal: null,
          value: null,
          isValid: false,
        },

        last_name: {
          valueOriginal: null,
          value: null,
          isValid: false,
        },

        email: {
          valueOriginal: null,
          value: null,
          isValid: false,
        },

        linkedin: {
          valueOriginal: null,
          value: null,
          isValid: true,
        },

        facebook: {
          valueOriginal: null,
          value: null,
          isValid: true,
        },

        twitter: {
          valueOriginal: null,
          value: null,
          isValid: true,
        },

        invite_status: {
          valueOriginal: null,
          value: null,
          isValid: true,
        },

        invite_link: {
          valueOriginal: null,
          value: null,
          isValid: true,
        },
      })
    },

    async saveProfileData() {
      this.$store.commit('setIsLoading', true)
      let idStr = this.entityId == -1 ? '' : '/' + this.entityId
      let result = await fetchData(
        urls.partners + idStr,
        'POST',
        this.bodyToSave()
      )

      if (result?.popup_type === 'multi_message' && result?.need_to_popup) {
        this.$store.commit('setIsLoading', false)
        this.initCreatePartnerAlert(result)
        return
      }

      if (result?.message) {
        this.messageAfterSave = result.message
        this.isVisibleAfterSaveAlert = true
      } else if (!result || result?.detail) {
        console.error(result?.detail || 'Error')
      } else {
        if (this.entityId == -1) {
          this.entityId = result?.id ? result?.id : -1
        }
        if (this.entityId == -1) return

        await this.saveContactsData()
        this.$router.push('/link-builder-partner/' + this.entityId)
        this.initFormValues(result)
      }

      this.$store.commit('setIsLoading', false)
    },

    async saveContactsData() {
      this.$store.commit('setIsLoading', true)

      let requests = this.contacts.map((contact) => {
        if ('isMarkedToDelete' in contact && contact.isMarkedToDelete)
          return this.deleteContact(contact)
        else if (contact.id.value <= 0) return this.addContact(contact)
        else if (contact.id.value > 0 && this.smthContactChanged(contact))
          return this.updateContact(contact)
      })

      await Promise.all(requests).catch((err) => console.error(err))

      this.$store.commit('setIsLoading', false)
    },

    async addContact(contact) {
      let result = await fetchData(
        urls.partnerContact,
        'POST',
        this.bodyToSaveContact(contact)
      )

      if (result?.need_to_popup) {
        this.initSaveContactAlert(result)
      } else if (result?.detail) {
        console.error(result.detail)
      } else {
        this.initContactFormValues(result, contact.id.value)
      }
    },

    async updateContact(contact) {
      let idStr = '/' + contact.id.value
      let result = await fetchData(
        urls.partnerContact + idStr,
        'POST',
        this.bodyToSaveContact(contact)
      )

      if (result?.need_to_popup) {
        this.initSaveContactAlert(result)
      } else if (result?.detail) {
        console.error(result.detail)
      } else {
        this.initContactFormValues(result)
      }
    },

    async deleteContact(contact) {
      let idStr = '/' + contact.id.value
      let result = await fetchData(urls.partnerContact + idStr, 'DELETE')
      if (result?.detail) {
        console.error(result.detail)
      } else {
        let index = this.contacts.findIndex(
          (item) => item.id.value === contact.id.value
        )
        if (index > -1) this.contacts.splice(index, 1)
      }
    },

    initCreatePartnerAlert(result) {
      this.messageCreatePertner = ''

      result?.messages.forEach((item, index) => {
        this.messageCreatePertner += `
            <div ${index > 0 ? 'class="mt-10"' : ''}>${item.message}</div>
            <a href="/link-builder-partner/${item.id}" target="_blank">${
          item.name
        }</a>
            `
      })

      this.isVisibleCreatePartnerAlert = true
    },

    initSaveContactAlert(result) {
      this.messageSaveContact = `
        ${result.message ? '<div>' + result.message + '</div> ' : ''}
        ${
          result?.name
            ? '<a href="' +
              '/link-builder-partner/' +
              result?.id +
              '" target="_blank">' +
              result?.name +
              '</a> '
            : ''
        }
        `
      this.isVisibleSaveContactAlert = true
    },

    bodyToSaveContact(contact) {
      let body = {}
      for (const contactKey in contact) {
        if (contact[contactKey].value !== contact[contactKey].valueOriginal) {
          body = { ...body, ...{ [contactKey]: contact[contactKey].value } }
        }
      }
      body = { ...body, ...{ partner_id: this.entityId } }

      return body
    },

    bodyToSave() {
      let body = {}
      for (const key in this.$data) {
        let dataItem = this.$data[key]
        if (
          dataItem &&
          typeof dataItem === 'object' &&
          'valueOriginal' in dataItem
        ) {
          if (dataItem.value && typeof dataItem.value === 'object') {
            if (
              JSON.stringify(dataItem.value) !==
              JSON.stringify(dataItem.valueOriginal)
            ) {
              dataItem.value.forEach((element) => {
                body = { ...body, ...{ [key]: element } }
              })
            }
          } else {
            if (dataItem.value !== dataItem.valueOriginal) {
              body = { ...body, ...{ [key]: dataItem.value } }
            }
          }
        }
      }

      if (this.entityId == -1) {
        body = {
          ...body,
          ...{ emails: this.contacts.map((item) => item.email.value) },
        }
      }

      return body
    },

    async fetchDataStatus() {
      let query = `page=1&per_page=all&partner_status=asc`
      let result = await fetchData(urls.partnerStatuses, 'GET', null, query)

      this.status.dataList = (result?.rows || []).map((item) => {
        return {
          id: item.partner_status,
          name: item.partner_status,
        }
      })
    },

    async fetchDataLink_builder() {
      let query = `page=1&per_page=all&email=asc`
      let result = await fetchData(urls.users, 'GET', null, query)

      this.link_builder.dataList = (result?.rows || []).map((item) => {
        return {
          id: item.email,
          name: item.email,
        }
      })
    },

    async fetchDataSlack_community_status() {
      let query = `page=1&per_page=all&email=asc`
      let result = await fetchData(
        urls.slackCommunityStatus,
        'GET',
        null,
        query
      )

      this.slack_community_status.dataList = (result?.rows || []).map(
        (item) => {
          return {
            id: item.slack_community_status,
            name: item.slack_community_status,
          }
        }
      )
    },

    async fetchDataAdditional_requirements() {
      let query = `page=1&per_page=all&requirement=asc`
      let result = await fetchData(urls.requirements, 'GET', null, query)

      this.additional_requirements.dataList = (result?.rows || []).map(
        (item) => {
          return {
            id: item.requirement,
            name: item.requirement,
          }
        }
      )
    },
  },
}
</script>
